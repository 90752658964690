@defer (on idle) {
  <app-popover-wrapped
    #popover
    [popOverTarget]="popOverTarget()"
    [placement]="placement()"
    [arrowCenter]="arrowCenter()"
    [mockParentSize]="mockParentSize()"
  >
    <ng-content />
  </app-popover-wrapped>
}
