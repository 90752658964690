import { ChangeDetectionStrategy, Component, input, ViewChild } from '@angular/core';
import type { Placement } from '@floating-ui/dom';
import { AppPopoverComponent } from '../popover.component';

@Component({
  selector: 'app-popover',
  standalone: true,
  imports: [AppPopoverComponent],
  templateUrl: './popover-wrapper.component.html',
  styleUrl: './popover-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPopoverWrapperComponent {
  private openPopoverCalledBeforeLoaded = false;
  _popover: AppPopoverComponent | undefined;
  @ViewChild('popover', { static: false }) set popover(content: AppPopoverComponent) {
    if (content) {
      this._popover = content;
      if (this.openPopoverCalledBeforeLoaded) {
        this.openPopoverCalledBeforeLoaded = false;
        this.openPopover();
      }
    }
  }
  /**
   * The target element that will trigger the popover. Witch the popover is relative to position.
   */
  popOverTarget = input.required<HTMLElement>();
  /**
   * The placement of the popover relative to the popOverTarget element. Values bottom or top
   * Bottom by default.
   */
  placement = input<Placement>('bottom');
  /**
   * If true, the arrow of the popover will be on the center of the popOverTarget element.
   * Else the center of the popover will be on the center of the popOverTarget element.
   */
  arrowCenter = input<boolean>(false);

  /**
   * If true, the popover will be anchored to the parent size.
   */
  mockParentSize = input<boolean>(false);

  openPopover() {
    if (!this._popover) {
      this.openPopoverCalledBeforeLoaded = true;
      return;
    }
    this._popover.openPopover();
  }
}
